var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_c('Comeback'),_vm._v(" "+_vm._s(_vm.$t('sales_analysis_report'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading},on:{"click":_vm.exportExcel}},[_vm._v(" "+_vm._s(_vm.$t('excel'))+" ")]),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading,"icon":"","fab":"","outlined":""},on:{"click":_vm.exportExcel}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFileExcelOutline))])],1)],1),_c('DateFilters',{on:{"onSendDate":_vm.addPayload}}),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.course_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.course_name)+" ")])]}},{key:"item.course_price",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.course_price)+" ")])]}},{key:"item.customer_old",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.customer_old)+" | "+_vm._s(item.customer_old_price||'0.00')+" ")])]}},{key:"item.customer_new",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.customer_new)+" | "+_vm._s(item.customer_new_price||'0.00')+" ")])]}},{key:"item.customer_all",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.customer_all)+" ")])]}},{key:"item.course_count",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.course_count)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }